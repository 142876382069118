import { styled } from "@mui/system";

export const StyledIngress = styled("div")({
  backgroundColor: "#7b92a0",
  color: "white",
  width: "100%",
  display: "flex",
  justifyContent: "center",
});

export const StyledContent = styled("div")({
  maxWidth: 700,
  padding: "32px",
});

export const StyledImg = styled("img")({
  margin: "16px -20px",
  width: 200,
  height: 115,
});
